// 根据一个数组的顺序排序另一个数组
export function sortLists (sortList, dragArr) {
  sortList.sort((a, b) => {
    return dragArr.indexOf(a.dataIndex) - dragArr.indexOf(b.dataIndex)
  })
  return sortList
}
export const filterItems = [
  {
    title: '日期',
    key: 'date',
    dataIndex: 'date',
    id: '1',
    checked: false,
    disabled: true
  },
  {
    title: '应用',
    key: 'stsDesc',
    id: '2',
    dataIndex: 'stsDesc',
    checked: false,
    disabled: true
  },
  {
    key: 'budget',
    title: '广告位',
    id: '3',
    checked: false,
    dataIndex: 'budget'
  },
  {
    title: '广告样式',
    dataIndex: 'adPlanName',
    id: '4',
    key: 'adPlanName',
    checked: false
  },
  {
    title: '广告平台',
    dataIndex: 'adPlanNames',
    id: '5',
    key: 'adPlanNames',
    checked: false
  },
  {
    key: 'putStartDate',
    title: '系统类型',
    id: '6',
    checked: false,
    dataIndex: 'putStartDate'
  }
]
export const basicAttributes = [
  {
    id: '1',
    title: '日期',
    key: 'date',
    dataIndex: '1',
    checked: true,
    disabled: true
  },
  {
    id: '2',
    title: '应用',
    key: 'stsDesc',
    dataIndex: '2',
    checked: true,
    disabled: true
  },
  {
    id: '3',
    title: '广告位',
    key: 'budget',
    dataIndex: '3',
    checked: false,
    disabled: false
  },
  {
    id: '4',
    title: '广告样式',
    key: 'adPlanName',
    dataIndex: '4',
    checked: false,
    disabled: false
  },
  {
    id: '5',
    title: '广告平台',
    key: 'putStartDate',
    dataIndex: '5',
    checked: false,
    disabled: false
  },
  {
    id: '6',
    title: '系统类型',
    key: 'setting',
    dataIndex: '6',
    checked: false,
    disabled: false
  }
]
export const disAttributes = [
  {
    id: '1',
    title: 'DAU',
    key: 'impress',
    dataIndex: '7',
    checked: false,
    disabled: false
  },
  {
    id: '2',
    title: '流量请求',
    key: 'click',
    dataIndex: '8',
    checked: false,
    disabled: false
  },
  {
    id: '3',
    title: '流量填充',
    key: 'clickRate',
    dataIndex: '9',
    checked: false,
    disabled: false
  },
  {
    id: '4',
    title: '流量填充率',
    key: 'ecpm',
    dataIndex: '10',
    checked: false,
    disabled: false
  },
  {
    id: '5',
    title: '展示',
    key: 'ecpc',
    dataIndex: '11',
    checked: true,
    disabled: false
  },
  // {
  //   id: '6',
  //   title: '展示率',
  //   key: 'ecpc',
  //   dataIndex: '12',
  //   checked: false,
  //   disabled: false
  // },
  {
    id: '7',
    title: '点击',
    key: 'ecpc',
    dataIndex: '13',
    checked: true,
    disabled: false
  },
  {
    id: '8',
    title: '点击率',
    key: 'ecpc',
    dataIndex: '14',
    checked: true,
    disabled: false
  },
  {
    id: '9',
    title: 'eCPM',
    key: 'ecpc',
    dataIndex: '15',
    checked: true,
    disabled: false
  },
  {
    id: '10',
    title: '收益',
    key: 'ecpc',
    dataIndex: '16',
    checked: true,
    disabled: false
  },
  {
    id: '11',
    title: '人均收益',
    width: 150,
    key: 'income',
    checked: false,
    sorter: true,
    dataIndex: '17'
  },
  {
    id: '6',
    title: '展示率',
    width: 150,
    key: 'unitImpressionRate',
    checked: false,
    sorter: true,
    dataIndex: '12'
  }
]
